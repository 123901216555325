import {Box, Button, Center, chakra, Grid, GridItem, Heading, Text, useMediaQuery, VStack} from '@chakra-ui/react';
import React, {useEffect, useRef} from 'react';
import {GetStaticProps} from 'next';
import NextImage, {StaticImageData} from 'next/image';
import Link from 'next/link';
import {NextSeo} from 'next-seo';
import {getCollections} from '../utils/shop_backend';
import {CollectionDataFragment} from '../lib/generated';
import {NextChakraLink} from '../components/next-chakra-link';
import {ENABLE_PRODUCT_DROP} from '../lib/consts';
import {shopifyImageLoader} from '../utils/image-loader';
import dropBackground from '../public/images/drop_background.jpg';


const IndexPage = ({collections}: { collections: CollectionDataFragment[] }) => {
    const collectionsRef = useRef<null | HTMLDivElement>(null);

    const grid: JSX.Element[] = [];

    const bigImageIndex = 4;
    for (let i = 1; i < 8; i++) {
        grid.push(<GridItem
            key={i}
            display={i === bigImageIndex ? ['none', 'none', 'block'] : 'block'}
            colSpan={i === bigImageIndex ? 2 : 1}
            position={'relative'}
            h={'400px'}
        >
            <NextImage
                quality={'40'}
                sizes={'50vw'}
                alt={'Collection image'}
                fill
                src={`/images/grid/${i}.jpg`}
                style={{objectFit: 'cover', objectPosition: 'center'}}
            />
        </GridItem>)
    }

    collections.filter(collection => collection.metafield?.value).map(collection => {
        return <GridItem key={collection.id}><Link href={'/collections/' + collection.handle}><Box
            _hover={{opacity: 0.85}} transition={'all 0.2s'} cursor={'pointer'} h={'400px'}
            position={'relative'}>
            <NextImage
                loader={shopifyImageLoader}
                sizes={'50vw'}
                quality={'40'}
                alt={collection.image?.altText ?? ''}
                fill
                src={collection.image?.url}
                style={{objectFit: 'cover', objectPosition: 'center'}}
            />
            <Button position={'absolute'} top={'90%'} left={'50%'} transform={'translate(-50%, -90%)'}
                    colorScheme="cta" variant="solid">{collection.title}</Button>
        </Box></Link></GridItem>
    }).forEach((value, i) => {
        grid.splice(3 * i, 0, value);
    });

    return <>
        {ENABLE_PRODUCT_DROP ?
            <>
                <NextSeo
                    title={'Blickfang'}
                    openGraph={{
                        type: 'website',
                        locale: 'de_DE',
                        title: 'Blickfang',
                        images: [{url: 'https://blckfng.com/images/grid/1.jpg'}],
                        description: 'NEUE KOLLEKTION: Vorbestellungen ab jetzt möglich!',
                    }}
                    twitter={{
                        cardType: 'summary_large_image',
                    }}
                />

                <VStack spacing={0} minH={'inherit'}>
                    <HeroVideo
                        video={'/videos/video_400_720.mp4'}
                        videoMobile={'/videos/video_mobile_350_720.mp4'}
                        poster={'/videos/video_preview.png'}
                        posterMobile={'/videos/video_mobile_preview.png'}
                    >
                        <Heading size={'3xl'} fontWeight={'900'} pt={'63vh'} pb={0}>NEUE KOLLEKTION</Heading>
                        <Button colorScheme="cta" variant="solid" onClick={() => {
                            if (collectionsRef?.current) collectionsRef.current.scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest',
                                inline: 'start',
                            })

                        }
                        }>Produkte ansehen</Button>
                    </HeroVideo>
                    <Grid ref={collectionsRef} templateColumns={['repeat(2, 1fr)', null, 'repeat(4, 1fr)']} pt={'10px'}
                          backgroundColor={'black'}
                          w={'100%'}
                          gap="10px">
                        {grid}
                    </Grid>
                </VStack> </> :
            <>
                <NextSeo
                    title={'Blickfang'}
                    openGraph={{
                        type: 'website',
                        locale: 'de_DE',
                        title: 'Blickfang',
                        images: [{url: 'https://blckfng.com/images/drop_background.jpg'}],
                        description: 'Bestellungen werden voraussichtlich am 7.11 versendet.',
                    }}
                    twitter={{
                        cardType: 'summary_large_image',
                    }}
                />
                <Hero image={dropBackground} imageOpacity={0.5}>
                    <Heading pt={'200px'} pb={'20px'}>Vielen Dank für eure Bestellungen!</Heading>
                    <Text>Nächster Drop im Dezember.<br/>
                        folge uns auf <NextChakraLink textDecorationStyle={'dotted'} textDecoration={'underline'}
                                                      href={'https://instagram.com/blickfang_de'}>Instagram</NextChakraLink> für
                        aktuelle
                        Informationen!
                    </Text>
                </Hero></>}

    </>;
};


const Hero = ({
                  image,
                  imageOpacity,
                  children,
              }: { image: string | StaticImageData, imageOpacity: number, children: React.ReactNode }) => {
    return <Box w={'100%'} minH={'inherit'} position={'relative'}>
        <Box position={'absolute'} opacity={imageOpacity} left={'0'} top={'0'} w={'100%'} zIndex={-1} h={'100vh'}>
            <NextImage
                priority={true}
                quality={70}
                fill
                alt={'Hero image'}
                src={image}
                placeholder={'blur'}
                style={{objectFit: 'cover'}}
            />
        </Box>
        <Center position={'relative'} p={'10px'}>
            <VStack textAlign={'center'}>
                {children}
            </VStack>
        </Center></Box>;

};

const HeroVideo = ({
                       videoMobile,
                       video,
                       poster, posterMobile,
                       children,
                   }: { video: string, videoMobile: string, poster: string, posterMobile: string, children: React.ReactNode }) => {

    const [isLargerThan600] = useMediaQuery('(min-width: 600px)', {
        ssr: true,
        fallback: false, // return false on the server, and re-evaluate on the client side
    })
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        videoRef.current?.load();
    }, [isLargerThan600]);

    return <Box w={'100%'} minH={'inherit'} position={'relative'}>
        <Box position={'absolute'} left={'0'} top={'0'} w={'100%'} zIndex={-1} h={'100vh'}>
            <chakra.video poster={isLargerThan600 ? poster : posterMobile} ref={videoRef} objectFit={'cover'}
                          minHeight={'100%'} minWidth={'100%'} autoPlay
                          loop muted playsInline>
                {isLargerThan600 && <source src={video} type="video/mp4"/>}
                {!isLargerThan600 && <source src={videoMobile} type="video/mp4"/>}
            </chakra.video>
        </Box>
        <Center position={'relative'} p={'10px'}>
            <VStack textAlign={'center'}>
                {children}
            </VStack>
        </Center></Box>;

};

export const getStaticProps: GetStaticProps = async () => {
    const collections = await getCollections();
    if (!collections) {
        return {
            notFound: true,
            revalidate: 20,
        };
    }

    return {
        props: {
            collections,
        },
        revalidate: 60,
    };
};

export default IndexPage;
